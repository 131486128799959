import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Layout from 'components/Layout';
import Container from 'react-bootstrap/Container';

import './404.scss';

const NotFoundPage = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
}) => {
  const {
    pageNotFound: { text, headerText, buttonText, buttonUrl },
  } = useStaticQuery(graphql`
    query {
      pageNotFound {
        text
        headerText
        buttonText
        buttonUrl {
          url
        }
      }
    }
  `);
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: false,
  };

  const [{ url }] = buttonUrl;

  return (
    <Layout crumbsSettings={crumbsSettings}>
      <section className="not-found container-wrapper">
        <Container fluid className="not-found__content">
          <div className="not-found__content">
            <p className="not-found__header">{headerText}</p>
            <p className="not-found__text">{text}</p>
          </div>

          <Link className="not-found__buttonlink" to={url}>
            {buttonText}
          </Link>
        </Container>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
